import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { jSith } from "../../../../util/jquery-replacement";
import { PricingService } from "../../../../services/pricing.service";
import {
    AccountOccurrenceSubtotal,
    LineItemI,
    lineItemType,
    PricingModel,
    PricingOccurrence,
    UpdateData,
} from "../../../../pojo/Pricing";
import { S25Util } from "../../../../util/s25-util";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { S25ModalComponent } from "../../../s25-modal/s25.modal.component";
import { ModalData } from "../../../modal/modal.service";
import { S25PricingUtil } from "../../s25.pricing.util";

@TypeManagerDecorator("s25-ng-pricing-adjustment")
@Component({
    selector: "s25-ng-pricing-adjustment",
    template: `@if (init) {
        <div>
            <!-- Footer adjustment row--->
            @if (!modelBean.bill_item_id) {
                @if (sumProfileOccAdjustments !== undefined) {
                    <div class="pricing-tooltip-wrapper">
                        {{ sumProfileOccAdjustments | currency }}
                        <s25-ng-info-message [isPopover]="true">
                            <h4>{{ tooltipHeader }}</h4>
                            <p>{{ tooltipMessage }}</p>
                        </s25-ng-info-message>
                    </div>
                }
                @for (item of dollarAdjustments; track $index) {
                    <div
                        class="c-margin-bottom--half"
                        [class.adj-item-wrapper]="allowAdjustment"
                        (click)="editAdjustment(item, false)"
                        (keydown.enter)="editAdjustment(item, false)"
                        tabindex="0"
                        aria-label="Edit Adjustment"
                    >
                        @if (item.adjustment_name) {
                            <div class="PricingTaxName">{{ item.adjustment_name }}</div>
                        }
                        <span>{{ item.adjustment_amt | currency }}</span>
                        @if (allowAdjustment) {
                            <span class="edit-icon c-margin-left--half">
                                <svg class="c-svgIcon">
                                    <title>Edit Adjustment</title>
                                    <use
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#edit"
                                    ></use>
                                </svg>
                            </span>
                        }
                    </div>
                }
                @for (item of percAdjustments; track $index) {
                    <div
                        class="c-margin-bottom--half"
                        [class.adj-item-wrapper]="allowAdjustment"
                        (click)="editAdjustment(item, false)"
                        (keydown.enter)="editAdjustment(item, false)"
                        tabindex="0"
                        aria-label="Edit Adjustment"
                    >
                        @if (item.adjustment_name) {
                            <div class="PricingTaxName">{{ item.adjustment_name }}</div>
                        }
                        <span>{{ item.adjustment_percent }}</span>
                        @if (allowAdjustment) {
                            <span class="edit-icon c-margin-left--half">
                                <s25-ng-icon [type]="'edit'"></s25-ng-icon>
                            </span>
                        }
                    </div>
                }
                @if (allowAdjustment) {
                    <div>
                        <button
                            class="aw-button aw-button--outline c-margin-top--half"
                            (click)="editAdjustment({}, true)"
                        >
                            <svg class="c-svgIcon">
                                <title>Add Adjustment</title>
                                <use
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#plus"
                                ></use>
                            </svg>
                            <span>Add</span>
                        </button>
                    </div>
                }
            }
            <!-- Line item adjustment --->
            @if (modelBean.summaryView) {
                @if (modelBean.adjustment_name) {
                    <div class="PricingTaxName adj-item">
                        {{ modelBean.adjustment_name }}
                    </div>
                }
                @if (S25Util.isDefined(modelBean.adjustmentAmt) && !modelBean.orgId) {
                    <div>{{ modelBean.adjustmentAmt | currency }}</div>
                }
            }
            @if (modelBean.bill_item_id && !modelBean.subtotal && !modelBean.summaryView) {
                @if (invoiceLocked || !modelBean.canEdit || !modelBean.canEditPricing) {
                    <div>
                        @if (modelBean.adjustment_name) {
                            <div class="PricingTaxName adj-item">
                                {{ modelBean.adjustment_name }}
                            </div>
                        }
                        <span class="adj-entry">{{
                            modelBean.adjustment_amt
                                ? (modelBean.adjustment_amt | currency)
                                : (modelBean.adjustment_percent ?? "$0.00")
                        }}</span>
                    </div>
                }

                @if (modelBean.canEdit && modelBean.canEditPricing && cellHasData && !invoiceLocked) {
                    <div
                        (click)="editAdjustment()"
                        (keydown.enter)="editAdjustment()"
                        tabindex="0"
                        aria-label="Edit Adjustment"
                        class="adj-item-wrapper"
                    >
                        @if (modelBean.adjustment_name) {
                            <div class="PricingTaxName adj-item">
                                {{ modelBean.adjustment_name }}
                            </div>
                        }
                        @if (modelBean.adjustment_amt) {
                            <span class="adj-entry">{{ modelBean.adjustment_amt | currency }}</span>
                        }
                        @if (modelBean.adjustment_percent) {
                            <span class="adj-entry">{{ modelBean.adjustment_percent }}</span>
                        }
                        <span class="edit-icon c-margin-left--half">
                            <s25-ng-icon [type]="'edit'"></s25-ng-icon>
                        </span>
                    </div>
                }
                @if (modelBean.canEdit && modelBean.canEditPricing && !cellHasData && !invoiceLocked) {
                    <button class="add-button aw-button aw-button--outline" (click)="editAdjustment()">
                        <s25-ng-icon [type]="'plus'"></s25-ng-icon>
                        <span>Add</span>
                    </button>
                }
            }
            <!-- Occurrence adjustment - just display total adjustments for the occurrence --->
            @if (modelBean.subtotal && !modelBean.summaryView) {
                <div>{{ modelBean.subtotal.occurrenceAdjustments | currency }}</div>
            }
            <s25-ng-modal #pricingAdjModal [size]="'xs'">
                <ng-template #s25ModalBody>
                    <s25-ng-modal-pricing-adjustment [data]="modalData"></s25-ng-modal-pricing-adjustment>
                </ng-template>
            </s25-ng-modal>
        </div>
    }`,
    styles: `
        html.nm-party--on :host ::ng-deep .edit-icon {
            color: #6faee2;
        }

        .edit-icon {
            position: relative;
            bottom: 2px;
            left: 20px;
            color: #2573a7;
        }

        .adj-item-wrapper {
            width: 7em;
        }

        .adj-item-wrapper:hover {
            cursor: pointer;
        }

        .adj-item-wrapper:hover,
        .adj-item-wrapper:focus {
            outline: 2px solid #2573a7;
            outline-offset: 2px;
            border-radius: 3px;
        }

        html.nm-party--on :host ::ng-deep .adj-item-wrapper:hover,
        .adj-item-wrapper:focus {
            outline-color: #6faee2;
        }

        :host ::ng-deep button svg {
            position: relative;
            right: 2px;
            bottom: 1px;
        }

        .adj-item-wrapper:hover,
        .adj-item-wrapper:focus {
            outline: 2px solid #2573a7;
            outline-offset: 2px;
            border-radius: 3px;
        }

        :host ::ng-deep button svg {
            position: relative;
            right: 2px;
            bottom: 1px;
        }

        :host ::ng-deep button span {
            font-size: 13px;
            position: relative;
            top: 1px;
            right: 2px;
        }

        .adj-item {
            text-wrap: balance;
        }

        :host ::ng-deep .c-svgIcon {
            position: relative;
            bottom: 1px;
        }

        .pricing-tooltip-wrapper {
            margin-bottom: 5px;
        }

        :host ::ng-deep s25-ng-info-message {
            position: unset;
            transition: unset;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PricingAdjustmentComponent implements OnInit {
    @Input() modelBean: PricingAdjustmentModel;
    @Input() invoiceLocked: boolean;

    @Output() updateAdjustment: EventEmitter<UpdateData> = new EventEmitter<UpdateData>();

    @ViewChild("pricingAdjModal") pricingAdjModal: S25ModalComponent;

    init: boolean;
    dollarAdjustments: LineItemI[] = [];
    percAdjustments: LineItemI[] = [];
    cellHasData: boolean;
    sumProfileOccAdjustments: number;
    tooltipMessage: string = "Total of organization adjustments minus occurrence adjustments";
    tooltipHeader = "Total Profile Adjustments";
    modalData: ModalData & { adjustment: LineItemI & PricingOccurrence };
    allowAdjustment: boolean;

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit() {
        if (this.modelBean.summaryView) {
            if (this.modelBean.occurrence && !this.modelBean.isNonInvoiced) {
                // aggregate of all invoiced line item adjustments for an org
                this.modelBean.adjustmentAmt = this.modelBean.listItems?.reduce(
                    (sum: number, item: any) => sum + (item.adjustmentAmt ?? 0),
                    0,
                );
            } else if (this.modelBean.isNonInvoiced || this.modelBean.orgId < 0) {
                // non-invoiced adjustment
                const listPrice = this.modelBean.list_price ?? this.modelBean.listPrice ?? 0;
                const price = this.modelBean.taxable_amt ?? this.modelBean.price ?? 0;
                this.modelBean.adjustmentAmt = price - listPrice;

                if (this.modelBean.orgId < 0) {
                    this.sumProfileOccAdjustments = this.modelBean.noOccItems?.reduce(
                        (sum, item) => sum + ((item.taxable_amt ?? 0) - (item.list_price ?? 0)),
                        0,
                    );
                }
            }

            this.init = true;
            return;
        }
        this.allowAdjustment =
            this.modelBean.canAdjust &&
            this.modelBean.canEditPricing &&
            !this.modelBean.summaryView &&
            !this.invoiceLocked;
        this.cellHasData =
            this.modelBean.adjustment_percent || this.modelBean.adjustment_amt || this.modelBean.adjustment_name;

        this.modelBean.adjustment_amt &&= parseFloat(this.modelBean.adjustment_amt.toString()).toFixed(2);

        // standardize adjustment_percent display
        if (
            this.modelBean.adjustment_percent &&
            (typeof this.modelBean.adjustment_percent === "number" ||
                this.modelBean.adjustment_percent[this.modelBean.adjustment_percent.length - 1] !== "%")
        ) {
            this.modelBean.adjustment_percent = `${(
                parseFloat(this.modelBean.adjustment_percent.toString()) * 100
            ).toFixed(2)}%`;
        }

        if (this.modelBean.adjustmentType === 2 && this.modelBean.adjustment_percent) {
            this.modelBean.adjustment_percent = `${this.modelBean.adjustmentPercent.toString()}.00%`;
        }

        this.modelBean.onSave = this.onUpdate.bind(this);

        // process footer adjustments
        if (this.modelBean.allBillItems?.length > 0) {
            if (this.modelBean.occView) {
                const nonOccAdjustments = S25PricingUtil.agg.adjustments.subtotal(
                    this.modelBean.noOccItems.filter((item: LineItemI) => item.bill_item_type_id !== 2),
                );

                this.sumProfileOccAdjustments =
                    this.modelBean.profileSubtotals.reduce(
                        (sum: number, item: LineItemI) => sum + (item.profileAdjustments ?? 0),
                        0,
                    ) -
                    nonOccAdjustments -
                    this.modelBean.profileSubtotals.reduce(
                        (sum: number, item: LineItemI) => sum + (item.occurrenceAdjustments ?? 0),
                        0,
                    );
            }
            jSith.forEach(this.modelBean.allBillItems, (_, billItem) => {
                if (billItem.bill_item_type_id === lineItemType.ADJUSTMENT) {
                    if (
                        billItem.adjustment_percent ||
                        (billItem.adjustment_percent === 0 && S25Util.isUndefined(billItem.adjustment_amt)) //ANG-4959 Lets any comment be edited while staying type consistent
                    ) {
                        if (
                            typeof billItem.adjustment_percent === "number" ||
                            (billItem.evBillId && billItem.adjustment_percent.at(-1) !== "%")
                        ) {
                            billItem.adjustment_percent = `${(
                                parseFloat(billItem.adjustment_percent.toString()) * 100
                            ).toFixed(2)}%`;
                        }
                        this.percAdjustments.push(billItem);
                    } else if (billItem.adjustment_amt) {
                        this.dollarAdjustments.push(billItem);
                    } else {
                        //ANG-4959 allow editing of any stray adjustments they may show up in other places
                        billItem.adjustment_amt = 0;
                        this.dollarAdjustments.push(billItem);
                    }
                }
            });
        }

        this.init = true;
    }

    editAdjustment(billItem?: any, isNew?: boolean) {
        if (
            !this.modelBean.canEdit ||
            !this.modelBean.canEditPricing ||
            this.modelBean.summaryView ||
            this.invoiceLocked
        )
            return;

        if (isNew) {
            const { eventId, canEdit, canEditPricing, orgId, evBillId } = this.modelBean;

            billItem = {
                ...billItem,
                eventId,
                canEdit,
                canEditPricing,
                charge_to_id: orgId,
                evBillId,
            };
        }

        if (billItem && !billItem.eventId) billItem.eventId = this.modelBean.eventId;

        if (this.modelBean.profileId) this.modelBean.isNew = !this.cellHasData;

        this.modalData = {
            adjustment: billItem
                ? Object.assign(billItem, { adjustmentType: 99, isNew, onSave: this.modelBean.onSave })
                : this.modelBean,
        };
        this.cd.detectChanges();
        return this.pricingAdjModal.open();
    }

    onUpdate(pricingData: any) {
        const updatedRow = S25PricingUtil.processTableUpdate(pricingData, this.modelBean);
        this.updateAdjustment.emit(updatedRow);
        return this.pricingAdjModal.close();
    }

    protected readonly PricingService = PricingService;
    protected readonly S25Util = S25Util;
}

type PricingAdjustmentModel = {
    adjustmentType: 1 | 2 | 99; //type1 for line item adjustment, type2 for reservation adjustment, type99 for footer adjustment
    isNew: boolean;
    noOccItems: LineItemI[];
    occView: boolean;
    onSave: (pricingData: any) => void;
    profileId: number;
    subtotal: AccountOccurrenceSubtotal;
    summaryView: boolean;
} & PricingModel &
    Pick<LineItemI, "bill_item_id" | "charge_to_id" | "list_price" | "taxable_amount">;
