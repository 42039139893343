import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DestroyRef,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    signal,
} from "@angular/core";
import { S25Util } from "../../../../util/s25-util";
import { TypeManagerDecorator } from "../../../../main/type.map.service";
import { S25LoadingApi } from "../../../s25-loading/loading.api";
import { PricingService } from "../../../../services/pricing.service";
import { BalanceUpdateService } from "./balance.update.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { OrganizationI } from "../../../../pojo/OrganizationI";
import { EventI } from "../../../../pojo/EventI";
import { LineItemI } from "../../../../pojo/Pricing";
import { S25PricingUtil } from "../../s25.pricing.util";

@TypeManagerDecorator("s25-ng-pricing-organization")
@Component({
    selector: "s25-ng-pricing-organization",
    template: `@if (init()) {
        <div>
            <s25-loading-inline [model]="{}"></s25-loading-inline>
            @if (isBalanceDisplay) {
                <div class="balance-data">
                    <div class="balance-header">Outstanding Balance:</div>
                    <div>{{ modelBean.remainingBalance | currency }}</div>
                </div>
            }
            <!-- Editable Charge To Orgs -->
            @if (
                !isBalanceDisplay &&
                modelBean.canEdit &&
                modelBean.canEditPricing &&
                orgList.length > 1 &&
                !modelBean.listItems &&
                !invoiceLocked
            ) {
                <div>
                    <select
                        name="organizations"
                        class="cn-form__control"
                        aria-label="Select Charge To Organization"
                        [(ngModel)]="modelBean.selectedOrg"
                        (change)="orgChange()"
                    >
                        @for (org of orgList; track org.organization_id) {
                            <option [ngValue]="org">{{ org.organization_name }}</option>
                        }
                    </select>
                </div>
            }
            <!-- Read Only or only one Charge To option or invoice is locked-->
            @if (
                !isBalanceDisplay &&
                !modelBean.listItems &&
                (!modelBean.canEdit || !modelBean.canEditPricing || orgList.length <= 1 || invoiceLocked)
            ) {
                <span>{{ modelBean.itemName || this.modelBean.selectedOrg?.organization_name }}</span>
            }
        </div>
    }`,
    styles: `
        .balance-data {
            text-align: center;
        }

        .balance-data div:first-child {
            font-size: 90%;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25PricingOrganizationComponent implements OnInit {
    @Input() modelBean: PricingOrgModel;
    @Input() invoiceLocked: boolean;

    @Output() updateOrg: EventEmitter<any> = new EventEmitter<any>();

    init = signal<boolean>(false);
    orgList: OrganizationI[];
    isBalanceDisplay: boolean;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private balanceUpdateService: BalanceUpdateService,
        private destroyRef: DestroyRef,
    ) {}

    ngOnInit() {
        this.isBalanceDisplay = S25Util.isDefined(this.modelBean.remainingBalance);

        if (this.isBalanceDisplay) {
            this.balanceUpdateService
                .getBalance()
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe((balance) => {
                    if (this.init() && balance?.orgId === this.modelBean.orgId) {
                        this.updateBalanceView(balance?.amount);
                    }
                });
        }

        if (this.modelBean && !this.isBalanceDisplay) {
            const { charge_to_name, allOrgs, charge_to_id, chargeToId } = this.modelBean;

            if (!this.modelBean.isSummary) {
                //invoice or standard pricing
                this.modelBean.itemName = charge_to_name;
                this.orgList = allOrgs;

                if (this.orgList?.length > 0) {
                    this.modelBean.selectedOrg =
                        S25Util.array.getByProp(this.orgList, "organization_id", charge_to_id ?? chargeToId) ||
                        this.orgList[0];
                }
            } else if (chargeToId) {
                //summary view
                const org = this.modelBean.allOrgs?.find((org) => org.organization_id === chargeToId);
                this.modelBean.itemName = org?.organization_name ?? "";
            }
        }

        this.init.set(true);
    }

    async orgChange() {
        S25LoadingApi.init(this.elementRef.nativeElement);

        const {
            selectedOrg,
            eventData,
            eventId,
            bill_item_id,
            bill_item_type_id,
            ev_dt_profile_id,
            evBillId,
            profileId,
        } = this.modelBean;

        this.modelBean.itemName = selectedOrg.organization_name;

        try {
            const pricingData = await PricingService.putPricingLineItemChargeToId(
                eventId,
                selectedOrg.organization_id,
                bill_item_id,
                bill_item_type_id,
                ev_dt_profile_id ?? profileId,
                evBillId,
            );

            const billItems = S25Util.propertyGet(eventData, "bill_item");

            billItems.forEach((item: any) => {
                if (
                    parseInt(item.bill_item_id) === +bill_item_id &&
                    parseInt(item.bill_item_type_id) === +bill_item_type_id &&
                    parseInt(item.ev_dt_profile_id) === +ev_dt_profile_id
                ) {
                    item.charge_to_id = +selectedOrg.organization_id;
                    item.charge_to_name = selectedOrg.organization_name;
                    return;
                }
            });

            pricingData.newChargeToId = this.modelBean.selectedOrg.organization_id;
            const update = S25PricingUtil.processTableUpdate(pricingData, this.modelBean);

            this.updateOrg.emit(update);

            S25LoadingApi.destroy(this.elementRef.nativeElement);
        } catch (error) {
            S25LoadingApi.destroy(this.elementRef.nativeElement);
            console.error(error);
            alert("There was an error while saving the organization to the bill item");
        }
    }

    updateBalanceView(paymentAmt: number) {
        this.modelBean.totalPayments += paymentAmt * 100;
        this.modelBean.remainingBalance -= paymentAmt;
        this.cd.detectChanges();
    }
}

export type PricingOrgModel = {
    allOrgs: OrganizationI[];
    canEdit: boolean;
    canEditPricing: boolean;
    chargeToId?: number;
    evBillId: number;
    eventData: EventI;
    eventId: number;
    isSummary: boolean;
    itemName: string;
    listItems: LineItemI[];
    orgId: number;
    profileId: number;
    remainingBalance: number;
    selectedOrg: OrganizationI;
    totalPayments: number;
} & Pick<LineItemI, "bill_item_id" | "bill_item_type_id" | "charge_to_name" | "charge_to_id" | "ev_dt_profile_id">;
